.toolbar {
  padding: 0.5rem;
  margin: 10px;
  border: 1px solid #aaa;
}

button {
  box-sizing: border-box;
  padding: 6px 12px;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  color: #fff;
  background-color: #5cb85c;
  border: 2px solid #5cb85c;
}

button[disabled] {
  cursor: not-allowed;
  box-shadow: none;
  opacity: 0.3;
}

button:hover,
button:focus,
button:active {
  color: #5cb85c;
  background-color: #fff;
}

.hallosaip{
  background-color: yellow;
}

#wadahbayar{
  margin: 20px;
}