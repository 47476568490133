#mytable {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
}

.utamaRow,.utamaCol {
    text-align: left;
    padding: 16px;
}

.utamaRow:nth-child(even) {
    background-color: #f2f2f2;
}

#wadahKet{
    margin:20px;
    text-align: center;
}

#txtKeluarga{
    font-size: 25px;
    font-weight: bold;
}

#wadahBawah{
    text-align: right;
    margin:20px;
}
@media print {
}
footer {page-break-after: always;}

#mytable{
    width:100%
}

#width2 {width:2%}
#width10 {width:10%}
#width7 {width:7%}